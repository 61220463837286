import makeStyles from '@material-ui/core/styles/makeStyles'

const styles = makeStyles(theme => ({
  container: {
    marginTop: '3rem',
    marginBottom: '3rem'
  }
}))

export default styles
